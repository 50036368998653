import {Box, IconButton, Stack, Table, TableBody, TableCell, TableHead, TableRow, Tooltip} from "@mui/material";
import HelpIcon from "@mui/icons-material/Help";
import {DateUtils} from "../../../book/DateUtils";
import {Link} from "react-router-dom";
import {AppRoute} from "../../../book/AppRoute";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import React, {FunctionComponent} from "react";
import {useTranslation} from "react-i18next";
import {
    ActivationItem,
    useGetApiV1UserMeQuery,
    usePostApiV1ProductLicenseMutation
} from "../../../api/CloudLicensesManagerApi";
import {FileDownload} from "@mui/icons-material";
import {ProcessStatusNotification, toProcessStatus} from "../process-status-notification/ProcessStatusNotification";
import {DownloadLicenseFile} from "../utils/CreateLicenseFile";
import {Loader} from "../loader/Loader";
import {ErrorMessage} from "../error-message/ErrorMessage";
import {Authorizations} from "../../../book/Authorizations";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";

export const ActivationsTable: FunctionComponent<ActivationsTableProps> = props => {
    const {t} = useTranslation();
    const [productLicense, {
        data: productLicenseData,
        isLoading: isProductLicenseLoading,
        error: productLicenseError,
        reset: resetProductLicense
    }] = usePostApiV1ProductLicenseMutation();
    const {data: userMe, isLoading: isMeLoading} = useGetApiV1UserMeQuery(undefined)

    if (!userMe || isMeLoading) {
        return <Loader message={t("Loading page, please wait...")}/>;
    }

    if (userMe.error) {
        return <ErrorMessage message={t("Error while loading page, please try again later")}/>;
    }

    const authorizations = userMe.payload!.authorizations;

    return <Table size="small" data-testid="activation-table">
        <ProcessStatusNotification
            status={toProcessStatus(isProductLicenseLoading, Boolean(productLicenseData?.payload), Boolean(productLicenseError))}
            onClose={() => resetProductLicense()}
            onLoadingMessage={t("Creating license.jlic file please wait…")}
            onSuccessMessage={t("license.jlic file created successfully")}
            onErrorMessage={t("Creating license.jlic file failed")}/>

        <TableHead>
            <TableRow>
                <TableCell>{t("Serial number")}</TableCell>
                <TableCell>{t("Product name")}</TableCell>
                <TableCell>{t("Company")}</TableCell>
                <TableCell>{t("Country")}</TableCell>
                <TableCell>{t("Activation date")}</TableCell>
                <TableCell>
                    {t("Last check date")}
                    <sup>
                        <Tooltip title={`${t("Last execution of license period check")}`}
                                 aria-label={t("Last execution of license period check")}>
                            <HelpIcon fontSize="inherit"/>
                        </Tooltip>
                    </sup>
                </TableCell>
                <TableCell/>
                <TableCell/>
            </TableRow>
        </TableHead>
        <TableBody>
            {props.activations.map(activation =>
                <TableRow key={activation.activationId} >
                    <TableCell title={activation.serialNumber} data-testid="column-serialNumber">
                        <Box title={activation.serialNumber}
                             sx={{
                                 overflow: 'hidden',
                                 textOverflow: 'ellipsis',
                                 display: '-webkit-box',
                                 '-webkit-line-clamp': '1',
                                 '-webkit-box-orient': 'vertical'
                             }}
                             >
                            {activation.serialNumber}
                        </Box>
                    </TableCell>
                    <TableCell data-testid="column-productName">{activation.productName}</TableCell>
                    <TableCell data-testid="column-company">{activation.company}</TableCell>
                    <TableCell data-testid="column-country">{activation.country}</TableCell>
                    <TableCell data-testid="column-activationDate">
                        <Box component="span">{DateUtils.dateFormat(activation.activationDate)}</Box>
                        <Box component="span" sx={{
                            display: {
                                md: 'none',
                                lg: 'inline-block'
                            }
                        }}>&nbsp;{DateUtils.timeFormat(activation.activationDate)}</Box>
                    </TableCell>
                    <TableCell data-testid="column-lastCheckDate">
                        <Box component="span">{DateUtils.dateFormat(activation.lastCheckDate)}</Box>
                        <Box component="span" sx={{
                            display: {
                                md: 'none',
                                lg: 'inline-block'
                            }
                        }}>&nbsp;{DateUtils.timeFormat(activation.lastCheckDate)}</Box>
                    </TableCell>
                    <TableCell sx={{whiteSpace: 'nowrap'}}>
                        {authorizations?.includes(Authorizations.LicenseDownload) && <IconButton
                            onClick={async () => {
                                try {
                                    const result = await productLicense({
                                        productLicenseRequest: {
                                            hardwareId: activation?.hardwareId,
                                            serialNumber: activation?.serialNumber,
                                        }
                                    });
                                    if ("data" in result && result.data?.payload?.jwtLicense) {
                                        DownloadLicenseFile(result.data.payload.jwtLicense);
                                    }
                                } catch (e) {
                                    console.log(e);
                                }
                            }}
                            color="primary"
                            title={t("Download .jlic file")}>
                            <FileDownload/>
                        </IconButton>}
                        {authorizations?.includes(Authorizations.ActivationDelete) && <IconButton
                            onClick={() => props.onFreeActivationClick(activation)}
                            color="primary"
                            title={t("Delete activation")}>
                            <DeleteForeverIcon/>
                        </IconButton>}
                    </TableCell>
                    <TableCell>
                        <IconButton
                            component={Link}
                            to={AppRoute.Activation.replace(":orderId", activation.orderId).replace(":activationId", activation.activationId)}>
                            <NavigateNextIcon/>
                        </IconButton>
                    </TableCell>
                </TableRow>)}
        </TableBody>
    </Table>
}

export interface ActivationsTableProps {
    activations: ActivationItem[];
    onFreeActivationClick: (activation: ActivationItem) => void;
}