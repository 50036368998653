import {useTranslation} from "react-i18next";
import React, {FunctionComponent, useEffect, useState} from "react";
import {PageTemplate} from "../../common/page-template/PageTemplate";
import {FormControl, InputAdornment, InputLabel, OutlinedInput, Stack} from "@mui/material";
import {
    useGetApiV1UserMeQuery,
    usePostApiV1TrialActivationListMutation
} from "../../../api/CloudLicensesManagerApi";
import {TableSkeleton} from "../../common/skeleton/TableSkeleton";
import {SearchInfo} from "../../common/search-info-grid/SearchInfo";
import {TrialActivationsTable} from "../../common/trial-activation-table/TrialActivationsTable";
import SearchIcon from "@mui/icons-material/Search";
import {ErrorMessage} from "../../common/error-message/ErrorMessage";
import {Loader} from "../../common/loader/Loader";
import {Authorizations} from "../../../book/Authorizations";

export const TrialActivationList: FunctionComponent = () => {
    const {t} = useTranslation();
    const [state, setState] = useState<TrialActivationListState>({
        currentPage: 0,
        pageSize: 50,
        search: ''
    });
    const [trialActivationsSearch, {
        data: trialActivationsSearchData,
        isLoading: isActivationsLoading,
        isError: isActivationsError,
        reset: resetSearchActivations
    }] = usePostApiV1TrialActivationListMutation();
    const {data: userMe, isLoading: isMeLoading} = useGetApiV1UserMeQuery(undefined)

    const loadTrialActivations = () => {
        trialActivationsSearch({
            trialActivationListRequest: {
                search: state.search.length ? state.search : null,
                page: state.currentPage,
                pageSize: state.pageSize
            }
        });
    }

    useEffect(() => {
        loadTrialActivations();
    }, [state.search, state.currentPage, state.pageSize]);

    if (!userMe || isMeLoading) {
        return <Loader message={t("Loading page, please wait...")}/>;
    }

    if (userMe.error) {
        return <ErrorMessage message={t("Error while loading page, please try again later")}/>;
    }

    const authorizations = userMe.payload!.authorizations;

    const searchResultTrialActivations = trialActivationsSearchData?.payload?.trialActivations ?? [];
    const searchResultCount = trialActivationsSearchData?.payload?.count ?? 0;
    const hardwareIdMorethanOneTrialNum = trialActivationsSearchData?.payload?.activateMoreTrialUserCount ?? 0;

    if (!authorizations!.includes(Authorizations.ActivationList)) {
        return <ErrorMessage message={t("You are not authorized to view this page")}/>;
    }

    const TrialActivationsSearchInfoGrid = () => <SearchInfo
        count={searchResultCount}
        currentPage={state.currentPage}
        pageSize={state.pageSize}
        onPageChange={page => {
            setState(state => ({...state, currentPage: page}));
        }}
        onPageSizeChange={pageSize => {
            setState(state => ({...state, pageSize}));
        }}
        paginationLabel={t("Orders per page")}
        itemsLabel={t("orders")}/>;

    return <PageTemplate pageTitle={t("Trial Activation list")}>
        <FormControl fullWidth variant="outlined">
            <InputLabel
                htmlFor="search-products">{t("Search trial activations by hardware ID or serial number…")}</InputLabel>
            <OutlinedInput value={state.search}
                           fullWidth
                           label={t("Search trial activations by hardware ID or serial number…")}
                           sx={{marginBottom: '20px'}}
                           onChange={e => {
                               setState(state => ({...state, search: e.target.value}))
                           }}
                           endAdornment={<InputAdornment position="end"><SearchIcon/></InputAdornment>}/>
        </FormControl>

        {isActivationsError && <ErrorMessage
            message={t("Error while loading trial activations. Please try again or contact the administrator")}/>}

        {isActivationsLoading &&
            <TableSkeleton sx={{marginTop: '40px'}}
                           colCount={5}
                           colCountMd={4}
                           rowCount={5}
                           showPagination/>}

        {Boolean(searchResultTrialActivations.length) && <Stack spacing={3} sx={{marginBottom: '15px', marginTop: '35px'}}>
            <TrialActivationsSearchInfoGrid/>

            <TrialActivationsTable 
                trialActivations={searchResultTrialActivations}
                count = {hardwareIdMorethanOneTrialNum}/>

            <TrialActivationsSearchInfoGrid/>
        </Stack>}

 
    </PageTemplate>;
}

export interface TrialActivationListState {
    currentPage: number;
    pageSize: number;
    search: string;
}