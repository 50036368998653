import * as React from "react";
import {FunctionComponent, useEffect} from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Badge from "@mui/material/Badge";
import {drawerWidth} from "./DrawerHeader";
import {AppRoute} from "../../../book/AppRoute";
import {useLocation, useNavigate} from "react-router-dom";
import Toolbar from "@mui/material/Toolbar";
import {Box, Divider, Drawer} from "@mui/material";
import GroupIcon from '@mui/icons-material/Group';
import DeviceHubIcon from '@mui/icons-material/DeviceHub';
import AdUnits from '@mui/icons-material/AdUnits';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import ViewListIcon from '@mui/icons-material/ViewList';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import {useTranslation} from "react-i18next";
import Inventory2Icon from '@mui/icons-material/Inventory2';
import {
    useGetApiV1UserMeQuery,
    usePostApiV1TrialActivationListMutation
} from "../../../api/CloudLicensesManagerApi";
import {Loader} from "../loader/Loader";
import {Authorizations} from "../../../book/Authorizations";
import {ErrorMessage} from "../error-message/ErrorMessage";

export const AppDrawer: FunctionComponent<AppDrawerProps> = props => {
    const {window} = props;
    const navigate = useNavigate();
    const location = useLocation();
    const {t} = useTranslation();
    const {data: userMe, isLoading: isMeLoading} = useGetApiV1UserMeQuery(undefined)
    const [trialActivationsSearch, {
            data: trialActivationsSearchData,
            isError: isActivationsError
        }] = usePostApiV1TrialActivationListMutation();

    const loadTrialActivations = () => {
        trialActivationsSearch({
            trialActivationListRequest: {
            }
        });
    }
    useEffect(() => {
        loadTrialActivations();
    }, []);
        
    if (!userMe || isMeLoading) {
        return <Loader message={t("Loading page, please wait...")}/>;
    }

    if (userMe.error) {
        return <ErrorMessage message={t("Error while loading page, please try again later")}/>;
    }

    const authorizations = userMe.payload!.authorizations;

    const hardwareIdMorethanOneTrialNum = trialActivationsSearchData?.payload?.activateMoreTrialUserCount ?? 0;
    const menu = [];

    if (authorizations!.find(auth => auth === Authorizations.OrderList)) {
        menu.push({
            name: t('Order list'),
            icon: <ShoppingCartIcon/>,
            link: AppRoute.OrderList,
        });
    }

    if (authorizations!.find(auth => auth === Authorizations.ActivationList)) {
        menu.push({
            name: t('Activation list'),
            icon: <DeviceHubIcon/>,
            link: AppRoute.ActivationList,
        });
    }

    if (authorizations!.find(auth => auth === Authorizations.ActivationList)) {
        menu.push({
            name: t('Trial Activations'),
            icon: <AdUnits/>,
            link: AppRoute.TrialActivationList,
        });
    }

    if (menu.length > 0) {
        menu.push(null);
    }

    if (authorizations!.find(auth => auth === Authorizations.UserList)) {
        menu.push({
            name: t('Users management'),
            icon: <GroupIcon/>,
            link: AppRoute.UsersManagement,
        });
    }

    if (authorizations!.find(auth => auth === Authorizations.ProductList)) {
        menu.push({
            name: t('Products'),
            icon: <Inventory2Icon/>,
            link: AppRoute.Products
        });
    }

    if (authorizations!.find(auth => auth === Authorizations.UserActivityLogList)) {
        menu.push({
            name: t('User activity log'),
            icon: <ViewListIcon/>,
            link: AppRoute.UserActivityLog,
        });
    }

    if (menu.length > 0) {
        menu.push(null);
    }

    menu.push({
        name: t('Credits'),
        icon: <FormatListBulletedIcon/>,
        link: AppRoute.Credits,
    });

    const container = window !== undefined ? () => window().document.body : undefined;

    const drawer = (
        <div>
            <Toolbar/>
            <Divider/>

            <List>
                {menu.map((menuItem, index) => {
                    if (!menuItem) {
                        return <Divider key={`divider-${index}`}/>;
                    }
                    const badgeNumber = (menuItem.name == 'Trial Activations') ? hardwareIdMorethanOneTrialNum : 0;
                    return <ListItem disablePadding
                                     sx={{
                                         display: 'block',
                                         '& .Mui-selected': {
                                             backgroundColor: 'rgba(0, 0, 0, 0.08)'
                                         }
                                     }}
                                     key={menuItem.name}>
                        <ListItemButton onClick={() => navigate(menuItem.link)}
                                        selected={menuItem.link === location.pathname}
                                        sx={{
                                            minHeight: 48,
                                            justifyContent: 'initial',
                                            px: 2.5
                                        }}>
                            <ListItemIcon
                                sx={{
                                    minWidth: 0,
                                    mr: 3,
                                    justifyContent: 'center',
                                }}>
                                {menuItem.icon}
                            </ListItemIcon>
                            <Badge badgeContent={badgeNumber} color="primary">
                                <ListItemText primary={menuItem.name}/>
                            </Badge>
                        </ListItemButton>
                    </ListItem>;
                })}
            </List>
        </div>
    );

    return <Box component="nav" sx={{width: {md: drawerWidth}, flexShrink: {md: 0}}}>
        <Drawer container={container}
                variant="temporary"
                open={props.isMobileOpen}
                onClose={props.handleDrawerToggle}
                ModalProps={{
                    keepMounted: true, // Better open performance on mobile.
                }}
                sx={{
                    display: {xs: 'block', md: 'none'},
                    '& .MuiDrawer-paper': {boxSizing: 'border-box', width: drawerWidth},
                }}>
            {drawer}
        </Drawer>
        <Drawer
            variant="permanent"
            sx={{
                display: {xs: 'none', md: 'block'},
                '& .MuiDrawer-paper': {boxSizing: 'border-box', width: drawerWidth},
            }}
            open>
            {drawer}
        </Drawer>
    </Box>;
}

export interface AppDrawerProps {
    userRole: number;
    window?: () => Window;
    handleDrawerToggle: () => void;
    isMobileOpen: boolean;
}