import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import React, {FunctionComponent, useState} from "react";
import {useTranslation} from "react-i18next";
import {Alert, Button, DialogActions, DialogContent, FormControl, InputLabel, Select, TextField} from "@mui/material";
import Typography from "@mui/material/Typography";
import {UserRole, UserRoleUtils} from "../../../book/UserRoleUtils";
import {PasswordUtils} from "../../../book/PasswordUtils";
import {usePostApiV1UserMutation, UserData} from "../../../api/CloudLicensesManagerApi";

const usernameValidation = (username: string): boolean => {
    const regexMatch = username
        .toLowerCase()
        .match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
    const carelDomain = username.endsWith("@carel.com");
    const partnersCarelDomain = username.endsWith("@partners.carel.com");
    return !!regexMatch && (carelDomain || partnersCarelDomain);
}

export const EditUserDialog: FunctionComponent<EditUserProps> = props => {
    let abortController: AbortController | undefined = undefined;
    const [state, setState] = useState<EditUserState>({
        email: props.user?.username ?? undefined,
        password: '',
        repeatPassword: '',
        role: props.user?.role as string,
        emailAlreadyExists: false
    })
    const {t} = useTranslation();
    const isUsernameValid = usernameValidation(state.email ?? '');
    const isPasswordValid = PasswordUtils.validate(state.password ?? '');
    const [searchUsers] = usePostApiV1UserMutation();

    const validateUsername = (username: string): void => {
        if (props.user.id || !username) {
            return;
        }

        if (abortController) {
            abortController.abort();
        }

        abortController = new AbortController();
        searchUsers({
            searchUsersRequest: {
                search: username
            }
        }).then(result => {
            if ('data' in result) {
                if (result.data.error) {
                    console.log(`Error while checking if email already exists: ${result.data.error}`);
                    return;
                }

                setState(state => ({
                    ...state,
                    emailAlreadyExists: result.data.payload?.users?.findIndex(u => u.username === username) !== -1
                }));
            }
        });
    };

    return <Dialog maxWidth="sm"
                   onClose={() => props.onCancelClick()}
                   aria-labelledby="edit-dialog-title"
                   open={true}
                   sx={{
                       '& .MuiFormControl-root + .MuiFormControl-root': {
                           marginTop: '1em'
                       },
                       '& .MuiAlert-root': {
                           marginTop: '1em'
                       },
                   }}>
        <DialogTitle id="edit-dialog-title">{props.user.id ? t("Edit user") : t("Add user")}</DialogTitle>
        <DialogContent dividers>
            <TextField disabled={UserRoleUtils.ToRole(props.user.role) === UserRole.Admin || !!props.user.id?.length}
                       variant="outlined"
                       label={t("Email")}
                       fullWidth
                       value={state?.email ?? ''}
                       onChange={e => {
                           const email = e.target.value as string;
                           setState(state => ({...state, email}))
                           validateUsername(email);
                       }}
                       inputProps={{
                           autoComplete: 'email',
                       }}/>

            <TextField variant="outlined"
                       label={t("Password")}
                       fullWidth
                       type="password"
                       helperText={t("passwordRole")}
                       value={state.password ?? ''}
                       onChange={e => setState(state => ({...state, password: e.target.value as string}))}
                       inputProps={{
                           autoComplete: 'new-password',
                       }}/>

            <TextField variant="outlined"
                       label={t("Confirm password")}
                       fullWidth
                       type="password"
                       value={state.repeatPassword ?? ''}
                       onChange={e => setState(state => ({...state, repeatPassword: e.target.value as string}))}
                       inputProps={{
                           autoComplete: 'repeat-password',
                       }}/>

            {state.email !== 'Admin' &&
                <FormControl variant="outlined" fullWidth>
                    <InputLabel htmlFor="role-select">{t("Role")}</InputLabel>
                    <Select native
                            value={state.role ?? ''}
                            onChange={e => setState(state => ({...state, role: e.target.value as string}))}
                            label={t("Role")}
                            inputProps={{
                                name: t("role"),
                                id: 'role-select',
                            }}>
                        <option aria-label="None" value=""/>
                        <option value="User">User</option>
                        <option value="Admin">Admin</option>
                    </Select>
                </FormControl>}

            {!!props.user.id && <Typography variant="caption">
                {t("If the password will not provided, it will not be updated.")}
            </Typography>}

            {state.password !== state.repeatPassword &&
                <Alert severity="warning">{t("Passwords do not match")}</Alert>}
            {(!!state.password && !isPasswordValid) &&
                <Alert severity="warning">{t("Password does not match rules")}</Alert>}
            {!state.email &&
                <Alert severity="warning">{t("Email is mandatory")}</Alert>}
            {(!isUsernameValid && state.email !== 'admin') &&
                <Alert severity="warning">{t("Email does not match rules")}</Alert>}
            {(state.emailAlreadyExists) &&
                <Alert severity="warning">{t("Email already used")}</Alert>}
            <Alert
                severity="info">{t("Password: 8-32 chars with at least: 1 lower case, 1 upper case, 1 number, 1 special char among: !\"#$%&'()*+,-./:;<=>?@[\\]^_`{|}~")}</Alert>
        </DialogContent>
        <DialogActions>
            <Button onClick={() => props.onOkClick(state.role, props.user.id, state.email, state.password)}
                    color="primary"
                    disabled={
                        state.password !== state.repeatPassword
                        || state.emailAlreadyExists
                        || !state.email
                        || !state.role
                        || (!!state.password && !isPasswordValid)
                        || (!isUsernameValid && state.email !== 'admin')
                    }>
                {props.user.id ? t("Edit") : t("Add")}
            </Button>
            <Button autoFocus onClick={() => props.onCancelClick()} color="primary">
                {t("Cancel")}
            </Button>
        </DialogActions>
    </Dialog>;
}

export interface EditUserProps {
    user: UserData;

    onCancelClick: () => void;
    onOkClick: (role: string | undefined, userId: string | undefined, email: string | undefined, password: string) => void;
}

interface EditUserState {
    email?: string;
    password: string;
    repeatPassword: string;
    role?: string;

    emailAlreadyExists: boolean;
}
