import React, {FunctionComponent} from "react";
import {Box, IconButton, Table, TableBody, TableCell, TableHead, TableRow} from "@mui/material";
import {ResponsiveTableCell} from "../../common/responsive-table-cell/ResponsiveTableCell";
import {Constants} from "../../../book/Constants";
import {OwnerDepartmentUtils} from "../../../book/OwnerDepartmentUtils";
import {DateUtils} from "../../../book/DateUtils";
import {Link} from "react-router-dom";
import {AppRoute} from "../../../book/AppRoute";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import {useTranslation} from "react-i18next";
import {OrderItem} from "../../../api/CloudLicensesManagerApi";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import {useGetApiV1UserMeQuery} from "../../../api/CloudLicensesManagerApi";
import {Loader} from "../../common/loader/Loader";
import {ErrorMessage} from "../../common/error-message/ErrorMessage";
import {Authorizations} from "../../../book/Authorizations";

export interface OrderTableProps {
    orders: OrderItem[];
    onDelete: (order: OrderItem) => void;
}

export const OrderTable: FunctionComponent<OrderTableProps> = props => {
    const {t} = useTranslation();
    const {data: userMe, isLoading: isMeLoading} = useGetApiV1UserMeQuery(undefined)
    if (!userMe || isMeLoading) {
        return <Loader message={t("Loading page, please wait...")}/>;
    }

    if (userMe.error) {
        return <ErrorMessage message={t("Error while loading page, please try again later")}/>;
    }

    const authorizations = userMe.payload!.authorizations;

    return <Table>
        <TableHead>
            <TableRow>
                <TableCell rowSpan={2}>
                    {t("Order number")}
                </TableCell>
                <TableCell rowSpan={2}>{t("Customer")}</TableCell>
                <TableCell colSpan={3} sx={{textAlign: 'center'}}>
                    {t("Serial numbers")}
                </TableCell>
                <ResponsiveTableCell hideDown="md" rowSpan={2}>{t("Reference email")}</ResponsiveTableCell>
                <ResponsiveTableCell hideDown="sm" rowSpan={2}>{t("Owner dept.")}</ResponsiveTableCell>
                <ResponsiveTableCell hideDown="lg" rowSpan={2}>{t("Ref. date")}</ResponsiveTableCell>
                <TableCell rowSpan={2}/>
            </TableRow>
            <TableRow>
                <TableCell sx={{textAlign: 'center'}}>{t("Total")}</TableCell>
                <TableCell sx={{textAlign: 'center'}}>{t("Used")}</TableCell>
                <TableCell sx={{textAlign: 'center'}}>{t("Free")}</TableCell>
            </TableRow>
        </TableHead>
        <TableBody>
            {props.orders.map(order => <TableRow key={`order-table-row-${order.orderId}`}>
                <TableCell
                    sx={{
                        maxWidth: "150px",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                    }}>
                    <Box title={order.orderNumber}>{order.orderNumber}</Box>
                    {order.orderId === Constants.InternalActivationOrderId &&
                        <Box>(internal order)</Box>}
                </TableCell>
                <TableCell>{order.customerId} - {order.customerCode}</TableCell>
                <TableCell>{order.serialCount}</TableCell>
                <TableCell>{order.usedSerialCount}</TableCell>
                <TableCell>
                    {(order.serialCount) - (order.usedSerialCount)}
                </TableCell>
                <ResponsiveTableCell hideDown="md">
                    <a href={`mailto:${order.referenceEmail}`}
                       title={t("sendEmail", {email: order.referenceEmail})}>
                        {order.referenceEmail}
                    </a>
                </ResponsiveTableCell>
                <ResponsiveTableCell hideDown="sm">
                    {OwnerDepartmentUtils.getName(order.carelBranch)}
                </ResponsiveTableCell>
                <ResponsiveTableCell hideDown="lg">
                    {DateUtils.dateTimeFormat(order.referenceDate)}
                </ResponsiveTableCell>
                <TableCell sx={{whiteSpace: 'nowrap'}}>
                {authorizations?.includes(Authorizations.OrderDelete) && <IconButton
                            onClick={() =>{props.onDelete(order)} }
                            color="primary"
                            title={t("Delete order")}>
                            <DeleteForeverIcon/>
                        </IconButton>}
                    <IconButton
                        title={t("orderDetail", {orderNumber: order.orderNumber})}
                        size="small"
                        aria-label={t("orderDetail", {orderNumber: order.orderNumber})}
                        component={Link}
                        to={`${AppRoute.Order.replace(':orderId', order.orderId)}`}>
                        <NavigateNextIcon/>
                    </IconButton>
                </TableCell>
            </TableRow>)}
        </TableBody>
    </Table>;
}