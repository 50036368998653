import {useTranslation} from "react-i18next";
import React, {FunctionComponent, useEffect, useState} from "react";
import {PageTemplate} from "../../common/page-template/PageTemplate";
import {FormControl, InputAdornment, InputLabel, OutlinedInput, Stack} from "@mui/material";
import {
    ActivationItem,
    useGetApiV1UserMeQuery,
    usePostApiV1ActivationListMutation,
    usePostApiV1FreeActivationMutation
} from "../../../api/CloudLicensesManagerApi";
import {TableSkeleton} from "../../common/skeleton/TableSkeleton";
import {SearchInfo} from "../../common/search-info-grid/SearchInfo";
import {
    ProcessStatusNotification,
    toProcessStatus
} from "../../common/process-status-notification/ProcessStatusNotification";
import {FreeActivationDialog} from "../../common/free-activation-dialog/FreeActivationDialog";
import {ActivationsTable} from "../../common/activation-table/ActivationsTable";
import SearchIcon from "@mui/icons-material/Search";
import {ErrorMessage} from "../../common/error-message/ErrorMessage";
import {Loader} from "../../common/loader/Loader";
import {Authorizations} from "../../../book/Authorizations";

export const ActivationList: FunctionComponent = () => {
    const {t} = useTranslation();
    const [state, setState] = useState<ActivationListState>({
        currentPage: 0,
        pageSize: 50,
        search: ''
    });
    const [activationsSearch, {
        data: activationsSearchData,
        isLoading: isActivationsLoading,
        isError: isActivationsError,
        reset: resetSearchActivations
    }] = usePostApiV1ActivationListMutation();
    const [freeActivation, {
        data: freeActivationData,
        isLoading: isFreeActivationLoading,
        error: freeActivationError,
        reset: resetFreeActivation
    }] = usePostApiV1FreeActivationMutation();
    const {data: userMe, isLoading: isMeLoading} = useGetApiV1UserMeQuery(undefined)

    const loadActivations = () => {
        activationsSearch({
            activationListRequest: {
                search: state.search.length ? state.search : null,
                page: state.currentPage,
                pageSize: state.pageSize
            }
        });
    }

    useEffect(() => {
        loadActivations();
    }, [state.search, state.currentPage, state.pageSize]);

    if (!userMe || isMeLoading) {
        return <Loader message={t("Loading page, please wait...")}/>;
    }

    if (userMe.error) {
        return <ErrorMessage message={t("Error while loading page, please try again later")}/>;
    }

    const authorizations = userMe.payload!.authorizations;

    const searchResultActivations = activationsSearchData?.payload?.activations ?? [];
    const searchResultCount = activationsSearchData?.payload?.count ?? 0;

    if (!authorizations!.includes(Authorizations.ActivationList)) {
        return <ErrorMessage message={t("You are not authorized to view this page")}/>;
    }

    const ActivationsSearchInfoGrid = () => <SearchInfo
        count={searchResultCount}
        currentPage={state.currentPage}
        pageSize={state.pageSize}
        onPageChange={page => {
            setState(state => ({...state, currentPage: page}));
        }}
        onPageSizeChange={pageSize => {
            setState(state => ({...state, pageSize}));
        }}
        paginationLabel={t("Activations per page")}
        itemsLabel={t("activations")}/>;

    return <PageTemplate pageTitle={t("Activation list")}>
        <FormControl fullWidth variant="outlined">
            <InputLabel
                htmlFor="search-products">{t("Search activations by hardware ID or serial number…")}</InputLabel>
            <OutlinedInput value={state.search}
                           fullWidth
                           label={t("Search activations by hardware ID or serial number…")}
                           sx={{marginBottom: '20px'}}
                           onChange={e => {
                               setState(state => ({...state, search: e.target.value}))
                           }}
                           endAdornment={<InputAdornment position="end"><SearchIcon/></InputAdornment>}/>
        </FormControl>

        {isActivationsError && <ErrorMessage
            message={t("Error while loading activations. Please try again or contact the administrator")}/>}

        {isActivationsLoading &&
            <TableSkeleton sx={{marginTop: '40px'}}
                           colCount={5}
                           colCountMd={4}
                           rowCount={5}
                           showPagination/>}

        {Boolean(searchResultActivations.length) && <Stack spacing={3} sx={{marginBottom: '15px', marginTop: '35px'}}>
            <ActivationsSearchInfoGrid/>

            <ActivationsTable activations={searchResultActivations}
                              onFreeActivationClick={(activation) => {
                                  setState(state => ({...state, activationToFree: activation}));
                              }}/>

            <ActivationsSearchInfoGrid/>

        </Stack>}

        <ProcessStatusNotification
            status={toProcessStatus(isFreeActivationLoading, Boolean(freeActivationData?.payload), Boolean(freeActivationError))}
            onClose={() => resetFreeActivation()}
            onLoadingMessage={t("Freeing activation please wait…")}
            onSuccessMessage={t("Activation freed successfully")}
            onErrorMessage={t("Activation freeing failed")}/>

        <FreeActivationDialog open={Boolean(state.activationToFree)}
                              activation={state.activationToFree}
                              onCancelClick={() => {
                                  setState(state => ({
                                      ...state,
                                      activationToFree: undefined
                                  }));
                              }}
                              onOkClick={() => {
                                  const activationId = state.activationToFree?.activationId;

                                  setState(state => ({
                                      ...state,
                                      activationToFree: undefined
                                  }));

                                  freeActivation({
                                      freeActivationRequest: {
                                          activationId: activationId
                                      }
                                  }).then(_ => {
                                      resetSearchActivations();
                                      loadActivations();
                                  });
                              }}/>
    </PageTemplate>;
}

export interface ActivationListState {
    currentPage: number;
    pageSize: number;
    search: string;
    activationToFree?: ActivationItem;
}