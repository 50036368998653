import {Box, IconButton, Stack, Table, TableBody, TableCell, TableHead, TableRow, Tooltip} from "@mui/material";
import {DateUtils} from "../../../book/DateUtils";
import React, {FunctionComponent} from "react";
import {useTranslation} from "react-i18next";
import {
    TrialActivationItem,
    useGetApiV1UserMeQuery,
} from "../../../api/CloudLicensesManagerApi";
import {Loader} from "../loader/Loader";
import {ErrorMessage} from "../error-message/ErrorMessage";

function GetStyle(highlight:boolean){
    if(highlight)
        return {background: 'red'}

}
export const TrialActivationsTable: FunctionComponent<TrialActivationsTableProps> = props => {
    const {t} = useTranslation();
    const {data: userMe, isLoading: isMeLoading} = useGetApiV1UserMeQuery(undefined)

    if (!userMe || isMeLoading) {
        return <Loader message={t("Loading page, please wait...")}/>;
    }

    if (userMe.error) {
        return <ErrorMessage message={t("Error while loading page, please try again later")}/>;
    }

    const authorizations = userMe.payload!.authorizations;

    return <Table size="small" data-testid="trial-activation-table">
        <TableHead>
            <TableRow>  
                <TableCell>{t("HardwareId")}</TableCell>
                <TableCell>{t("ActivationId")}</TableCell>
                <TableCell>{t("Activation Date")}</TableCell>
                <TableCell>{t("Product Name")}</TableCell>
            </TableRow>
        </TableHead>
        <TableBody>
            {props.trialActivations.map(trialActivation =>
                <TableRow key={trialActivation.hardwareId} sx={GetStyle(trialActivation.activateTrialMultiTimes)}>
                    <TableCell title={trialActivation.hardwareId} data-testid="column-hardwareId">
                        <Box title={trialActivation.hardwareId}
                             sx={{
                                 overflow: 'hidden',
                                 textOverflow: 'ellipsis',
                                 display: '-webkit-box',
                                 '-webkit-line-clamp': '1',
                                 '-webkit-box-orient': 'vertical'
                             }}
                             >
                            {trialActivation.hardwareId}
                        </Box>
                    </TableCell>
                    <TableCell data-testid="column-activationId">{trialActivation.activationId}</TableCell>
                    <TableCell data-testid="column-activationDate">{DateUtils.dateTimeFormat(trialActivation.activationDate!)}</TableCell>
                    <TableCell data-testid="column-Product">{trialActivation.product?.id}</TableCell>
                </TableRow>)}
        </TableBody>
    </Table>
}

export interface TrialActivationsTableProps {
    trialActivations: TrialActivationItem[];
    count:number;
}